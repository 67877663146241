'use strict';

Gri.module({
  name: 'sidebar-alpha',
  ieVersion: null,
  $el: $('.sidebar-alpha'),
  container: '.sidebar-alpha',
  fn: function () {
    
    var winwidth = $(document).width();
    var scrolltopheight = $('.header-alpha').height() + $('.breadcrumb-alpha').height() + 60;
    /*
      $(document).scroll(function() {
        if(winwidth > 990){
          if($(document).scrollTop() > scrolltopheight){
            $('.sidebar-alpha').css({
              top: "20px",
              position: "fixed"
            })
          }else{
            $('.sidebar-alpha').css({
              top: "20px",
              position: "relative"
            })
          }
        }
      });
    */
  }
});
